import React, { useState } from 'react'

import {
  addNotification,
  Button,
  Buttons,
  Heading,
  Modal,
  ModalBody,
  Notifications,
  Paragraph
} from '@te-digi/styleguide'

const PatternExampleJulkaise = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false)

  const onModalOpenClick1 = () => setIsModalOpen1(isModalOpen => !isModalOpen)
  const onModalClose1 = () => setIsModalOpen1(false)
  const onModalClose2 = () => {
    setIsModalOpen1(false)
    addNotification('Tiedot julkaistiin onnistuneesti.', {
      status: 'success'
    })
  }

  return (
    <>
      <Button
        color="secondary"
        onClick={onModalOpenClick1}
      >
        Julkaise
      </Button>
      <Modal
        ariaLabelledby="modal-heading"
        isOpen={isModalOpen1}
        onRequestClose={onModalClose1}
        size="lg"
      >
        <ModalBody>
          <Heading
            id="modal-heading"
            size={4}
          >
            Julkaistaanko tiedot?
          </Heading>
          <Paragraph>
            Voit muokata tietoja tai piilottaa ne muilta käyttäjiltä julkaisun
            jälkeenkin.
          </Paragraph>
          <Buttons
            align="right"
            noMargin
          >
            <Button
              onClick={onModalClose1}
              variant="outline"
            >
              Peruuta
            </Button>
            <Button
              color="secondary"
              onClick={onModalClose2}
            >
              Julkaise
            </Button>
          </Buttons>
        </ModalBody>
      </Modal>
      <Notifications />
    </>
  )
}

export { PatternExampleJulkaise }
